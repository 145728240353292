import React, {FC} from 'react'

import cn from 'classnames/bind'

import HeadingFooter from 'components/elements/footer/HeadingFooter'
import MenuFooter from 'components/elements/footer/MenuFooter'
import MenuFooterPrivacy from 'components/elements/footer/MenuFooterPrivacy'
import {Col, Container, Row} from 'components/elements/grid'
import IconPhone from 'src/assets/icons/phone.svg'
import EqualwebButton from 'src/components/elements/EqualwebButton/EqualwebButton'
import Logo from 'src/components/elements/Logo'

import s from './Footer.module.scss'
const cx = cn.bind(s)

const footerMenuLeft = [
	{
		id: 1,
		title: 'Contact us',
		slug: '/contact',
	},
	{
		id: 2,
		title: 'Paystub',
		slug: '/make-your-paystub',
	},
	{
		id: 3,
		title: 'Invoice',
		slug: '/invoice-generator',
	},
	{
		id: 4,
		title: 'W-2 form',
		slug: '/make-your-w2',
	},
	{
		id: 5,
		title: '1099',
		slug: '/make-your-1099',
	},
]

const footerMenuRight = [
	{
		id: 1,
		title: 'Home',
		slug: '/',
	},
	// {
	// 	id: 2,
	// 	title: 'Reviews',
	// 	slug: '/reviews',
	// },
	{
		id: 3,
		title: 'Pricing',
		slug: '/pricing',
	},
	{
		id: 4,
		title: 'Faq',
		slug: '/faq',
	},
	{
		id: 5,
		title: 'Blog',
		slug: '/blog',
	},
]

const footerPrivacy = [
	{
		id: 1,
		title: 'Privacy Policy',
		slug: '/privacy-policy',
	},
	{
		id: 2,
		title: 'Terms and Conditions',
		slug: '/terms',
	},
]

interface IFooterProps {
	className?: string
}

const Footer: FC<IFooterProps> = ({className}) => {
	return (
		<footer className={cx('footer', className)}>
			<Container>
				<Row>
					<Col md={5} lg={4}>
						<Logo type="transparent" className={cx('logo')} />
						<div className="d-none d-md-block">
							<MenuFooter menu={footerMenuLeft.slice(0, 1)} className={cx('nav-contact')} />
						</div>
					</Col>
					<Col md={7} lg={5}>
						<div className={cx('nav-wrapper')}>
							<MenuFooter menu={footerMenuLeft.slice(1)} className={cx('nav__left')} />
							<MenuFooter menu={footerMenuRight} className={cx('nav__right')} />
						</div>
						<div className="d-block d-md-none">
							<MenuFooter menu={footerMenuLeft.slice(0, 1)} className={cx('nav-contact')} />
						</div>
						<div className="d-block d-md-none">
							<MenuFooterPrivacy menu={footerPrivacy} />
						</div>
					</Col>
					<Col lg={3}>
						<div className={cx('contact-wrapper')}>
							<div className={cx('contact-right')}>
								<HeadingFooter>Need Service?</HeadingFooter>
								<div className={cx('phone')}>
									<IconPhone />
									<a href="tel:855-607-1274">
										<span className={cx('phone__number')}>+1 (855) 607-1274</span>
									</a>
								</div>
								<EqualwebButton className={cx('contact-right__accessibe')} />
							</div>
						</div>
					</Col>
				</Row>
			</Container>

			<div className={cx('bottom-block')}>
				<Container>
					<div className={cx('bottom')}>
						<address className={cx('bottom__address')}>
							1875 Century Park E, Los Angeles, CA 90067
						</address>

						<div className={cx('d-none', 'd-md-block', 'bottom__menu')}>
							<MenuFooterPrivacy menu={footerPrivacy} />
						</div>
						<span className={cx('bottom__copyright')}>
							Copyright &copy; {new Date().getFullYear()} Form Source, LLC. All Rights Reserved
						</span>
					</div>
				</Container>
			</div>
		</footer>
	)
}

Footer.displayName = 'Footer'

export default Footer
